<template>
  <div class="d-flex justify-content-between m-5 flex-wrap">
    <div class="lesson-dets d-flex flex-column gap-3 col-lg-8 col-sm-12">
      <h6>{{ course_name }}</h6>
      <p>{{ course_description }}</p>
      <div class="d-flex justify-content-between align-items-center flex-wrap">
        <div class="bagdes-container d-flex gap-2 flex-wrap">
          <span class="video-num-wraper">
            <img :src="videoSvg" alt="videos" />
            <span class="videos-num">{{ course_video_count }} فيديو</span>
          </span>
          <span class="exams-num-wraper">
            <img :src="examSvg" alt="exams" />
            <span class="exams-num">{{ course_exam_count }} امتحان</span>
          </span>
          <span class="hws-num-wraper">
            <img :src="hwSvg" alt="home works" />
            <span class="hws-num">{{ course_homework_count }} واجب</span>
          </span>
        </div>
        <span class="course-price">{{ course_price }}</span>
      </div>
    </div>
    <div class="lesson-card d-flex flex-column gap-3 col-lg-3 col-sm-12">
      <div class="img-container">
        <img :src="course_image" alt="">
      </div>
      <div class="d-flex justify-content-between">
        <span>إجمالي الساعات</span>
        <p>{{total_course_video_duration}} ساعة</p>
      </div>
      <!-- <div class="d-flex justify-content-between">
        <span>إجمالي الاسئلة</span>
        <p>+60 سؤال</p>
      </div> -->
    </div>
  </div>
  <div class="m-5">
    <div class="w-100">
      <!-- Accordion for each lesson -->
      <div class="accordion accordion-flush exam-acc" id="accordionFlushExample">
        <div class="accordion-item" v-for="(lesson, index) in lessons" :key="lesson.id">
          <h2 class="accordion-header" :id="'flush-heading' + index">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
              :data-bs-target="'#flush-collapse' + index" aria-expanded="false"
              :aria-controls="'flush-collapse' + index">
              <div class="exam-title d-flex">
                <h6>{{ lesson.name }}</h6>
              </div>
            </button>
          </h2>
          <div :id="'flush-collapse' + index" class="accordion-collapse collapse"
            :aria-labelledby="'flush-heading' + index" data-bs-parent="#accordionFlushExample">
            <div class="accordion-body exam-desc">
              <p>{{ lesson.description }}</p>
              <div class="d-flex gap-4 numbers-accordion flex-wrap">
                <div class="d-flex align-items-center">
                  <img :src="clipboard" alt="Clipboard Icon">
                  <p>مدة الفيديو : {{ lesson_duration_minutes }} دقيقة</p>
                </div>
                <!-- <div class="d-flex align-items-center">
                  <img :src="time" alt="Time Icon">
                  <p>مدة الأمتحان : 10 مشاهدات</p>
                </div>
                <div class="d-flex align-items-center">
                  <img :src="trick" alt="Trick Icon">
                  <p>عدد المجتازين للأمتحان : 1000</p>
                </div> -->
                <div class="exam-start">
                  <router-link :to="{ name: 'LessonsVideo', params: { lesson_id: lesson.id } }">
                    بدء الدرس
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import apiService from '@/apiService';

export default {
  name: 'EachLesson',
  data() {
    return {
      course_name: '',
      course_price: '',
      course_description: '',
      course_image: '',
      lesson_duration_minutes: '',
      course_homework_count: '',
      course_exam_count: '',
      course_video_count: '',
      total_course_video_duration: '',
      clipboard: require('@/assets/images/clipboard-text.svg'),
      trick: require('@/assets/images/profile-tick.svg'),
      time: require('@/assets/images/time.svg'),
      videoSvg: require('@/assets/images/video-play.svg'),
      examSvg: require('@/assets/images/note.svg'),
      hwSvg: require('@/assets/images/note-add.svg'),
      lessons: [],  // Store all lessons here
    };
  },
  mounted() {
    const courseId = this.$route.params.id;
    this.getLessons(courseId);

    const Collapse = require('bootstrap/js/dist/collapse');
    const accordionButtons = document.querySelectorAll('.accordion-button');
    accordionButtons.forEach((button) => {
      button.addEventListener('click', function () {
        const collapseElement = document.querySelector(button.getAttribute('data-bs-target'));
        if (collapseElement.classList.contains('show')) {
          Collapse.getInstance(collapseElement).hide();
        } else {
          Collapse.getOrCreateInstance(collapseElement).show();
        }
      });
    });
  },
  methods: {
    async getLessons(courseId) {
      try {
        const response = await apiService.getLessons(courseId);
        if (response.data.success) {
          const courseDetails = response.data.data[0]; // Access the first object in the data array
          this.course_name = courseDetails.course_name || '';
          this.course_price = courseDetails.course_price || '';
          this.course_description = courseDetails.course_description || '';
          this.course_image = courseDetails.course_image || '';
          this.course_homework_count = courseDetails.course_homework_count || '0';
          this.course_exam_count = courseDetails.course_exam_count || '0';
          this.course_video_count = courseDetails.course_video_count || '0';
          this.total_course_video_duration = courseDetails.total_course_video_duration|| '0';
          this.lesson_duration_minutes = courseDetails.lesson_duration_minutes || '';
          this.lessons = response.data.data;  // Store all lessons in the array
        } else {
          console.error('Error:', response.data.message);
        }
      } catch (error) {
        console.error('Error fetching lessons:', error);
      }
    }
  }
};
</script>



<style scoped>
.lesson-dets {
  border-radius: 32px;
  background: #FAFAFA;
  padding: 20px;
}

.lesson-dets h6 {
  color: #000;
  font-family: Cairo;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.56px;
  padding-bottom: 10px;
  border-bottom: 1px solid #E7E7E7;
}

.lesson-dets p {
  color: #8C8C8C;
  text-align: right;
  font-family: Cairo;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.course-price {
  color: #FAAF43;
  font-family: Cairo;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.img-container {
  border-bottom: 1px solid #E7E7E7;
  text-align: center;
  /* width: 50%; */
  align-self: center;
}

.img-container img {
  width: 100%;
  height: 100%;
  border-radius: 8px;
}

.lesson-card {
  border-radius: 32px;
  background: #FAFAFA;
  padding: 20px;
}

.lesson-card span {
  color: #8C8C8C;
  font-family: Cairo;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.lesson-card p {
  color: #000;
  font-family: Cairo;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
</style>