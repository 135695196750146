<template>
    <div class="d-flex flex-column w-100">
      <div class="old-exam-container"  v-for="(exam) in exams " :key="exam.exam_id">
        <div class="exam-title">
            <img :src="rightSrc" alt="Logo" />
            <p> {{ exam.exam_name }}</p>
        </div>
        <button  class="resultbtn" @click="openModal(exam.exam_id)">عرض النتيجة</button>
        <ResultModal :isOpen="isModalOpen" :examId="selectedExamId" @close="closeModal" />
    </div>
    </div>
</template>


<script>
    import ResultModal from './ResultModal.vue';
    import apiService from '@/apiService';

export default {
  name: 'CompletedExam',
  components:{
   ResultModal
  },
  data() {
    return {
        isModalOpen: false,
        selectedExamId:null,
      rightSrc: require('@/assets/images/right-green.svg'),
      exams:[]
    }
  },
  methods: {
    // goToExam() {
    //   this.$router.push({ name: 'OneExam' });
    // },
    openModal(examId) {
      this.isModalOpen = true;
      this.selectedExamId = examId;
    },
    closeModal() {
      this.isModalOpen = false;
    },
},
mounted() {
    // Fetch exams data from API when component is mounted
    apiService.getCompletedExams().then(response => {
      this.exams =response.data; // Assuming response.data.data contains the array of exams
    }).catch(error => {
      console.error("Error fetching exams:", error);
    });
  },
};
</script>

<style scoped>
    .old-exam-container{
        display: flex;
        height: fit-content;
        border-radius: 16px;
        background: #FAFAFA;
        padding: 20px 40px;
        /* width: 100%; */
        margin: 20px;
        justify-content: space-between;
    }
    .resultbtn{
        border-radius: 16px;
        background: #FAAF43;
        color: #FFF;
        font-family: Cairo;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        padding: 5px 30px;
        border: 0;
    }
    .exam-title{
        display: flex;
        width: 60%;
    }
    .exam-title p{
        color: #000;
        font-family: Cairo;
        font-size: 23px;
        font-style: normal;
        font-weight: 700;
        /* margin: 0; */
        margin-bottom: 0;
    }
</style>
