<template>
  <div class="lesson-video-container">
    <!-- Main Video -->
    <div class="d-flex justify-content-center m-4 w-100">
      <iframe 
        v-if="videoLink" 
        :key="videoLink" 
        :src="videoLink" 
        frameborder="0" 
        allowfullscreen 
        width="100%" 
        height="480px">
      </iframe>
    </div>

    <!-- Thumbnails Section -->
    <div class="video-thumbnails">
      <div 
        v-for="(video, index) in videos" 
        :key="index" 
        class="thumbnail-box" 
        @click="showVideo(index)"
        :class="{ active: index === currentIndex }"
      >
        <img :src="getThumbnail(video)" alt="Video Thumbnail" class="thumbnail-image" />
        <!-- <p class="video-title">{{ video.name }}</p> -->
      </div>
    </div>

    <!-- Next Video Button -->
    <button @click="nextVideo" class="btn-next align-self-start">الفيديو التالي</button>

    <!-- Modal Component -->
    <custom-modal
      :visible="isModalOpen"
      :title="modalTitle"
      :message="modalMessage"
      @close="closeModal"
      @confirm="closeModal"
    />
  </div>
</template>

<script>
import apiService from '@/apiService';
import CustomModal from './CustomModal.vue';

export default {
  name: 'LessonVideo',
  components: { CustomModal },
  data() {
    return {
      videoLink: '',
      videos: [],
      currentIndex: 0,
      isModalOpen: false,
      modalTitle: '',
      modalMessage: '',
    };
  },
  mounted() {
    const lessonId = this.$route.params.lesson_id;
    this.getVideos(lessonId);
  },
  methods: {
    async getVideos(lessonId) {
      try {
        const response = await apiService.getVideos(lessonId);
        if (response.data && response.data.data) {
          this.videos = response.data.data;
          this.showVideo(0);
        } else {
          console.error('Error:', response.data.message);
        }
      } catch (error) {
        console.error('Error fetching video data:', error);
      }
    },
    showVideo(index) {
      if (this.videos[index]) {
        const lesson = this.videos[index];
        this.videoLink = lesson.link; // No need to convert to embed URL
        this.currentIndex = index;
      }
    },
    nextVideo() {
      if (this.currentIndex < this.videos.length - 1) {
        this.currentIndex++;
        this.showVideo(this.currentIndex);
      } else {
        this.modalMessage = 'لا توجد فيديوهات اخرى';
        this.openModal();
      }
    },
    getThumbnail(video) {
      // If API provides an image, use it
      if (video.image) {
        return video.image.original_url;
      }

      // If no image is available, check if it's a YouTube video
      const youtubeRegex = /(?:youtube\.com\/(?:[^/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
      const match = video.link.match(youtubeRegex);
      if (match && match[1]) {
        return `https://img.youtube.com/vi/${match[1]}/mqdefault.jpg`;
      }

      // Default thumbnail if nothing is available
      return 'default-thumbnail.jpg';
    },
    openModal() {
      this.isModalOpen = true;
    },
    closeModal() {
      this.isModalOpen = false;
    }
  }
};
</script>



<style scoped>
.lesson-video-container {
  flex-shrink: 0;
  border-radius: 32px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px 7vw;
}

.video-thumbnails {
  display: flex;
  gap: 10px;
  overflow-x: auto;
  margin-top: 20px;
  padding-bottom: 10px;
  scrollbar-width: none;
  flex-wrap: wrap;
}

.thumbnail-box {
  cursor: pointer;
  border-radius: 10px;
  overflow: hidden;
  text-align: center;
  width: 206px;
  height: 119px;
  border: 3px solid transparent;
  transition: border-color 0.3s ease;
}

.thumbnail-box.active {
  border-color: #FAAF43;
}

.thumbnail-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
}

.video-title {
  font-size: 14px;
  color: #333;
  margin-top: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.btn-next {
  padding: 10px 45px;
  background-color: #FAAF43;
  border: none;
  color: white;
  border-radius: 10px;
  margin-top: 15px;
  cursor: pointer;
}
</style>
